import styled from 'styled-components'
import { grayscale, orange, white } from 'src/styles/colors'

export const RangeInputContainer = styled.div`
  .range-slider {
      box-sizing: border-box;
      display: inline-block;
      padding-bottom: 10px;
      position: relative;
      width: 95%;
      margin: 0 0 0 15px;

      & > input {
        cursor: -moz-grab;
        cursor: grab;
        opacity: 0;
        position: relative;
        bottom: 0px;
        width: 105%;
        z-index: 5;
        left: 0px;      
        top: 0px;
        margin: 0 -8px;

        &::-webkit-slider-thumb {
          appearance: none;
          border-radius: 10px;
          height: 20px;
          width: 50px;
          position: relative;
          z-index: 100;
        }
      }

      & > span.slider-container {
        box-sizing: border-box;
        display: inline-block;
        left: 0px;
        right: 0px;
        top: 8px;
        bottom: 0px;
        position: absolute;
        z-index: 3;
        height: 10px;
        width: 100%;  

        & > span.bar {
              background-color: ${grayscale[500]};
              border-radius: 10px;
              display: inline-block;
              left: 0px;
              height: 8px;
              overflow: hidden;
              position: absolute;
              right: 0px;
              top: 0px;
              z-index: 1;
          & > span {
            background: linear-gradient(2.37deg, ${orange.dark} 0%, ${orange.base} 100%);
            display: block;
            float: left;
            height: 11px;
          }
        }
        & > span.bar-btn {
          display: inline-block;
          position: absolute;
          top: 0px;

          &:after {
            background-color: ${white};
            border: 0.5px solid rgba(0, 0, 0, 0.1);
            box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1), 0px 3px 3px rgba(0, 0, 0, 0.05), 0px 3px 1px rgba(0, 0, 0, 0.05);
            border-radius: 20px;
            content: "";
            right:-10px;
            height: 25px;
            position: absolute;
            top: -8px;
            width: 25px;
            z-index: 3;
          }
        }
      }
    }
`
