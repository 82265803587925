import React, { useState, Dispatch, SetStateAction, useCallback } from 'react'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import UTMLink from 'src/components/CallToAction/ActionButtons/UTMLink'
import RangerInput from 'src/components/RangeInput'

import { Card, Input, Section } from './style'
interface IHeader {
  setSimulator: Dispatch<SetStateAction<string>>;
}

function covertValue (value: number) {
  return parseFloat(String(value) || '0').toLocaleString('pt-BR', {
    maximumFractionDigits: 2,
    maximumSignificantDigits: 2,
  })
}

const MIN_VALUE = 200000

const Hero = ({ setSimulator }: IHeader) => {
  const [ value, setValue ] = useState(200000)
  const [ sendDatalayerEvent ] = useDataLayer()
  const url = 'https://imobiliario.inter.co/financiamento-imobiliario/'

  const handlerChangeValue = useCallback(
    (number: number) => {
      if (String(number).length >= 8) return

      setValue(number)
    },
    [ value ],
  )

  return (
    <Section className='py-5 d-flex align-items-center'>
      <div className='container'>
        <div className='row display-flex align-items-center justify-content-between pl-md-3'>
          <div className='col-12 col-md-6 px-0 pb-5 pb-md-0 pr-md-2'>
            <h1 className='fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 text-white fw-600 mb-3 pr-lg-2'>
              Financiamento Imobiliário Inter com entrada a partir de 20%
            </h1>
            <p className='fs-18 lh-22 fs-md-16 lh-md-19 fs-lg-18 lh-lg-22 text-white px-0 mb-5 mb-md-0 pb-2'>
              Financie até 80% do valor do seu imóvel com a melhor condição do mercado.
            </p>
          </div>
          <div className='col-12 col-md-6 col-lg-5'>
            <Card>
              <div className='text-center'>
                <span className='fs-12 lh-15 fs-lg-15 lh-lg-19 fs-xl-18 lh-xl-24 text-grayscale--400 fw-400 mb-2'>
                  Qual o valor do imóvel?
                </span>
              </div>
              <div className='d-flex align-items-center justify-content-center'>
                <span className='fs-32 lh-40 fs-lg-40 lh-lg-50 fs-xl-50 lh-xl-60 fw-400 mb-0 text-grayscale--300 d-block'>
                  R${' '}
                </span>
                <Input
                  type='text'
                  value={covertValue(value)}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    handlerChangeValue(
                      Number(event.target.value.replace(/\D/g, '')),
                  )}
                />
              </div>
              <div className='text-center px-3 mb-2 mb-md-3'>
                <hr className='mt-0 mb-md-1' />
                <span className={`fs-10 lh-12 fs-lg-12 lh-lg-15 fs-xl-14 lh-xl-17 text-grayscale--400 d-block ${value < MIN_VALUE && 'text-error'}`}>
                  Valor mínimo de R$200.000
                </span>
              </div>
              <RangerInput
                initialValue={MIN_VALUE}
                minValue={MIN_VALUE}
                interval={1000}
                maxValue={3000000}
                onChange={setValue}
              />
              {value >= MIN_VALUE ? (
                <UTMLink
                  text='Simular meu financiamento'
                  link='SIMULADOR_FINANCIAMENTO_IMOBILIARIO'
                  campanha='site-pf-financiamento'
                  btncolor='btn-utm'
                  setSimulator={setSimulator}
                  dobra='dobra_01'
                  sectionName='Financiamento Imobiliário Inter: seu futuro começa agora'
                  sendDatalayerEvent={sendDatalayerEvent}
                  action='informacoes-imovel'
                  simulatorValue={Number(
                    covertValue(value).replace(/\D/g, ''),
                  )}
                />
                ) : (
                  <button
                    className='btn btn-utm' disabled={true}
                    onClick={() => {
                      sendDatalayerEvent({
                        section: 'dobra_01',
                        element_action: 'click button',
                        element_name: 'Simular meu financiamento',
                        redirect_url: url,
                       })
                    }}
                  >
                    Simular meu financiamento
                  </button>
                )}
            </Card>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default Hero
